import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import {
  ISlideShowGalleryOwnSDK,
  ISlideShowGalleryProps,
  ISlideShowGallerySDKFactory,
  SlideShowGallerySDKState,
} from '../SlideShowGallery.types';
import {
  clickActionSDKFactory,
  navigationButtonsSDKFactory,
  currentItemSDKFactory,
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';

const slideShowGallerySDKFactory: ISlideShowGallerySDKFactory = ({
  registerEvent,
  props,
  compRef,
  metaData,
}) => {
  const _state: SlideShowGallerySDKState = {
    navigationEndCallbacks: [],
  };

  registerEvent('onNavigationEnd', () => {
    _state.navigationEndCallbacks.forEach(cb => cb());
    _state.navigationEndCallbacks = [];
  });

  const sdkProps = {
    next() {
      return new Promise<void>(resolve => {
        _state.navigationEndCallbacks.push(resolve);
        compRef.moveToNextItem();
      });
    },
    previous() {
      return new Promise<void>(resolve => {
        _state.navigationEndCallbacks.push(resolve);
        compRef.moveToPrevItem();
      });
    },
    get currentIndex() {
      return props.currentIndex;
    },
    get galleryCapabilities() {
      return {
        hasCurrentItem: true,
        hasNavigationButtons: true,
        isAnimatable: true,
        isPlayable: true,
        supportsAllMediaTypes: false,
      };
    },
    get type() {
      return '$w.Gallery';
    },

    toJSON() {
      return {
        ...toJSONBase(metaData),
        clickAction: this.clickAction,
        currentIndex: this.currentIndex,
        // currentItem: this.currentItem,
        showNavigationButtons: this.showNavigationButtons,
        // items: ,
        type: '$w.Gallery',
      };
    },
  } as any;

  return sdkProps;
};

export const sdk = composeSDKFactories<
  ISlideShowGalleryProps,
  ISlideShowGalleryOwnSDK
>(
  clickActionSDKFactory,
  navigationButtonsSDKFactory,
  currentItemSDKFactory,
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  slideShowGallerySDKFactory,
);
