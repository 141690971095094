import {
  withValidation,
  composeSDKFactories,
  reportError,
  messages,
} from '@wix/editor-elements-corvid-utils';
import {
  ITimePickerProps,
  ITimePickerOwnSDKFactory,
  ITimePickerSDK,
  ITimePickerImperativeActions,
} from '../TimePicker.types';
import {
  readOnlyPropsSDKFactory,
  createValidationPropsSDKFactory,
  createRequiredPropsSDKFactory,
  focusPropsSDKFactory,
  disablePropsSDKFactory,
  changePropsSDKFactory,
  collapsedPropsSDKFactory,
  hiddenPropsSDKFactory,
  elementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import {
  createInputValidator,
  InputValidator,
  composeValidators,
  validateRequired,
} from '../../../core/corvid/inputUtils';
import {
  getLongTimeFromDate,
  isValidTimeFormat,
  completeToLongTime,
} from '../utils';

const timePickerValidator: InputValidator<
  ITimePickerProps,
  ITimePickerImperativeActions
> = createInputValidator(
  composeValidators<ITimePickerProps>([validateRequired]),
);

const validationPropsSDKFactory = createValidationPropsSDKFactory<
  ITimePickerProps,
  ITimePickerImperativeActions
>(timePickerValidator);

const requiredPropsSDKFactory = createRequiredPropsSDKFactory<
  ITimePickerProps,
  ITimePickerImperativeActions
>(timePickerValidator);

const _ownSDKFactory: ITimePickerOwnSDKFactory = api => {
  const { props, setProps, metaData } = api;

  const sdkProps = {
    get value() {
      if (props.initialTime === 'current' && !props.value) {
        const currentTime = getLongTimeFromDate(new Date(Date.now()));
        return currentTime;
      }
      return props.value;
    },
    set value(value) {
      const fullTime = completeToLongTime(value);
      setProps({ value: fullTime });

      timePickerValidator.validate({
        viewerSdkAPI: api,
        showValidityIndication: true,
      });
    },
    get step() {
      return props.step;
    },
    set step(step) {
      setProps({ step });
    },
    get useAmPmFormat() {
      return props.useAmPmFormat;
    },
    set useAmPmFormat(useAmPmFormat) {
      setProps({ useAmPmFormat });
    },
    toJSON() {
      const { value } = sdkProps;
      return {
        ...toJSONBase(metaData),
        value,
      };
    },
  };

  return sdkProps;
};

const ownSDKFactory = withValidation(
  _ownSDKFactory,
  {
    type: ['object'],
    properties: {
      value: {
        type: ['string', 'nil'],
      },
      step: {
        type: ['integer'],
        minimum: 1,
        maximum: 60,
      },
      useAmPmFormat: {
        type: ['boolean'],
      },
    },
  },
  {
    value: [
      _value => {
        if (!_value) {
          return true;
        }

        const isValid = isValidTimeFormat(_value);

        if (!isValid) {
          reportError(
            messages.invalidFormatMessageWithHint({
              propertyName: 'value',
              functionName: 'value',
              wrongValue: _value,
              hint: 'HH:MM, HH:MM:SS, or HH:MM:SS.mmm',
            }),
          );
        }

        return isValid;
      },
    ],
  },
);

export const sdk = composeSDKFactories<ITimePickerProps, ITimePickerSDK, any>(
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  disablePropsSDKFactory,
  focusPropsSDKFactory,
  readOnlyPropsSDKFactory,
  requiredPropsSDKFactory,
  validationPropsSDKFactory,
  changePropsSDKFactory,
  elementPropsSDKFactory,
  ownSDKFactory,
);
